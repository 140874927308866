const list = {
  header: [
    {
      id: "user.fullName",
      label: "User",
      orderBy: "user.fullName",
      align: "left",
      fallback: "N/A",
    },
    {
      id: "driver.fullName",
      label: "Driver",
      align: "left",
      fallback: "N/A",
    },
    {
      id: "class.title.en",
      label: "Class",
      align: "left",
      fallback: "N/A",
    },
    {
      id: "package.title.en",
      label: "Package",
      align: "left",
      fallback: "N/A",
    },
    {
      id: "pickup.location_name",
      label: "Pickup",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "pickupTime",
      label: "Pickup time",
      align: "left",
      orderBy: "pickup_time",
      fallback: "N/A",
    },
    {
      id: "destination.location_name",
      label: "Drop Off",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "price.base_fare",
      label: "Base fare",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "total",
      label: "Total Estimation",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "refunded_amount",
      label: "Refunded Amount (QAR)",
      order: false,
      align: "left",
    },
    {
      id: "payment_type.type.en",
      label: "Payment Method",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "payment_transaction_id",
      label: "Payment Transaction",
      align: "left",
    },
    {
      id: "status",
      label: "Status",
      align: "left",
      orderBy: "status",
      capitalize: true,
      fallback: "N/A",
    },
  ],
  hide: {
    create: true,
  },
  permission: "list",
  actions: ["show", "update"],
};

const show = {
  fields: [
    {
      type: "text",
      name: "user.fullName",
      label: "User",
    },
    {
      type: "text",
      name: "user.fullMobile",
      label: "Mobile",
    },
    {
      type: "mixed",
      name: "driver.first_name",
      label: "Driver",
      elements: [
        {
          type: "text",
          name: "en",
          label: "English",
        },
        {
          type: "text",
          name: "ar",
          label: "Arabic",
        },
      ],
    },
    {
      type: "mixed",
      name: "class.title",
      label: "Class",
      elements: [
        {
          type: "text",
          name: "en",
          label: "English",
        },
        {
          type: "text",
          name: "ar",
          label: "Arabic",
        },
      ],
    },
    {
      type: "mixed",
      name: "package.title",
      label: "Package",
      elements: [
        {
          type: "text",
          name: "en",
          label: "English",
        },
        {
          type: "text",
          name: "ar",
          label: "Arabic",
        },
      ],
    },
    {
      type: "text",
      name: "pickupTime",
      label: "Pickup time",
    },
    {
      type: "map",
      name: "pickup",
      label: "Pickup",
      long: {
        name: "lng",
        label: "Longitude",
      },
      apiKey: "AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4",
    },
    {
      type: "map",
      name: "destination",
      label: "Destination",
      long: {
        name: "lng",
        label: "Longitude",
      },
      apiKey: "AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4",
    },
    {
      type: "mixed",
      name: "guest",
      label: "Guest info",
      elements: [
        {
          type: "text",
          name: "luggage_number",
          label: "Luggage",
          nullable: true,
        },
        {
          type: "text",
          name: "fullname",
          label: "Full Name",
          nullable: true,
        },
        {
          type: "text",
          name: "mobile_number",
          label: "Mobile number",
          nullable: true,
        },
        {
          type: "text",
          name: "flight_number",
          label: "Flight number",
          nullable: true,
        },
        {
          type: "text",
          name: "signage_name",
          label: "Signage Name",
          nullable: true,
        },
      ],
    },
    {
      type: "text",
      name: "gate.airport.title.en",
      label: "Airport",
    },
    {
      type: "text",
      name: "gate.title.en",
      label: "Gate",
    },
    {
      type: "text",
      name: "status",
      label: "Status",
    },
  ],
  permission: "show",
};

const edit = {
  fields: [
    {
      type: "select",
      async: true,
      name: "driver_id",
      label: "Driver",
      required: true,
      route: "available-drivers?trip_id=",
      with_id: true,
    },
  ],
  permission: "show",
};

const filter = {
  fields: [
    {
      type: "select",
      name: "status",
      label: "Status",
      values: [
        { value: "accepted", label: "Accepted" },
        { value: "declined", label: "Declined" },
        { value: "pending", label: "Pending" },
      ],
    },
    {
      type: "select",
      async: true,
      name: "driver_id",
      route: "drivers-list",
      label: "Driver",
    },
    {
      type: "select",
      name: "class_id",
      label: "Car Class",
      async: true,
      route: "classes-list",
    },
    {
      type: "select",
      async: true,
      name: "package_id",
      route: "packages",
      label: "Packages",
    },
    {
      type: "select",
      async: true,
      name: "payment_id",
      route: "payment_methods",
      label: "Payment Methods",
    },
    {
      type: "date",
      name: "date.from",
      label: "Date From",
    },
    {
      type: "date",
      name: "date.to",
      label: "Date To",
    },
  ],
};

const search = {
  fields: [
    {
      type: "text",
      name: "$user.first_name$",
    },
    {
      type: "text",
      name: "$user.last_name$",
    },
    {
      type: "text",
      name: "status",
    },
  ],
};

const csv = {
  headers: [
    {
      label: "User",
      key: "user.fullName",
    },
    {
      label: "Driver",
      key: "driver.fullName",
    },
    {
      label: "Class",
      key: "class.title.en",
    },
    {
      label: "Package",
      key: "package.title.en",
    },
    {
      label: "Pickup time",
      key: "pickupTime",
    },
    {
      label: "Status",
      key: "status",
    },
  ],
};

const data = { list, show, edit, filter, search, csv };

export default data;
