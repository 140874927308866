const list = {
    header: [
        {
            id: 'appointment.guest.fullname',
            label: 'Customer name',
            fallback: 'N/A',
            align: 'left'
        },
        {
            id: 'hotel.title.en',
            label: 'Hotel',
            align: 'left'
        },
        {
            id: 'appointment.pickup.location_name',
            label: 'Pickup Location',
            align: 'left'
        },
        {
            id: 'appointment.destination.location_name',
            label: 'Drop off Location',
            align: 'left'
        },
        {
            id: 'appointment.bookingTime',
            label: 'Request Date & Time',
            align: 'left'
        },
        {
            id: 'appointment.driver.first_name.en',
            label: 'Driver Name',
            align: 'left'
        },
        {
            id: 'appointment.class.title.en',
            label: 'Car Class',
            align: 'left'
        },
        {
            id: 'appointment.price.base_fare',
            label: 'Base Fare (QAR)',
            align: 'left'
        },
        {
            id: 'appointment.total',
            label: 'Trip Total (QAR)',
            align: 'left'
        },
        {
            id: "appointment.refunded_amount",
            label: "Refunded Amount (QAR)",
            align: "left",
        },
    ],
    permissions: 'list',
    actions: ['show'],
};
const show = {
    fields: [
        {
            type: 'text',
            name: 'appointment.guest.fullname',
            label: 'Customer Name'
        },
        {
            type: 'text',
            name: 'hotel.title.en',
            label: 'Hotel Name'
        },
        {
            type: 'text',
            name: 'appointment.pickup.location_name',
            label: 'Pickup Location'
        },
        {
            type: 'text',
            name: 'appointment.destination.location_name',
            label: 'Drop Off Location'
        },
        {
            type: 'text',
            name: 'appointment.bookingTime',
            label: 'Request Date && time'
        },
        {
            type: 'text',
            name: 'appointment.driver.first_name.en',
            label: 'Driver Name'
        },
        {
            type: 'mixed',
            name: 'appointment.class.title',
            label: 'Package',
            elements: [
                {
                    type: 'text',
                    name: 'en',
                    label: 'English'
                },
                {
                    type: 'text',
                    name: 'ar',
                    label: 'Arabic'
                }
            ]
        },
        {
            type: 'text',
            name: 'appointment.price.base_fare',
            label: 'Base Fare (QAR)'
        },
        {
            type: 'text',
            name: 'appointment.total',
            label: 'Trip Total (QAR)'
        },
        {
            id: "appointment.refunded_amount",
            label: "Refunded Amount (QAR)",
            align: "left",
        },
    ],
    permission: 'show'
};

const search = {
    fields: [
        {
            type: 'text',
            name: '$appointment.user.first_name$'
        },
        {
            type: 'text',
            name: '$appointment.user.last_name$'
        },
    ]
};

const csv = {
    headers: [
        {
            key: 'appointment.guest.fullname',
            label: 'Customer name',
        },
        {
            key: 'hotel.title.en',
            label: 'Hotel',
        },
        {
            key: 'appointment.pickup.location_name',
            label: 'Pickup Location',
        },
        {
            key: 'appointment.destination.location_name',
            label: 'Drop off Location',
        },
        {
            key: 'appointment.bookingTime',
            label: 'Request Date & Time',
        },
        {
            key: 'appointment.driver.first_name.en',
            label: 'Driver Name',
        },
        {
            key: 'appointment.class.title.en',
            label: 'Car Class',
        },
        {
            key: 'appointment.price.base_fare',
            label: 'Base Fare (QAR)',
        },
        {
            key: 'appointment.total',
            label: 'Trip Total (QAR)',
        },
        {
            id: "appointment.refunded_amount",
            label: "Refunded Amount (QAR)",
            align: "left",
        },
    ]
}

const data = { list, show, search, csv };

export default data;