const list = {
  header: [
    {
      id: "user.fullName",
      label: "Customer Name",
      orderBy: "user.first_name",
      align: "left",
      fallback: "N/A",
    },
    {
      id: "pickup.location_name",
      label: "Pickup Location",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "destination.location_name",
      label: "Drop off Location",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "pickupTime",
      label: "Request Date & Time",
      align: "left",
      order: false,
      fallback: "N/A",
    },

    {
      id: "driver.fullName",
      label: "Driver Name",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "guest.fullname",
      label: "Guest",
      align: "left",
      fallback: "N/A",
      order: false,
    },

    {
      id: "class.title.en",
      label: "Car Class/Type",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "package.title.en",
      label: "Package",
      align: "left",
      order: false,
      fallback: "N/A",
    },

    {
      id: "total_distance",
      label: "Total Distance",
      align: "left",
      order: false,
      fallback: "N/A",
    },

    {
      id: "price.base_fare",
      label: "Base fare",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "total_time",
      label: "Total Time",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "total",
      label: "Trip total",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "refunded_amount",
      label: "Refunded Amount (QAR)",
      order: false,
      align: "left",
    },
    {
      id: "status",
      label: "Request Status",
      align: "left",
      orderBy: "status",
      capitalize: true,
      fallback: "N/A",
    },

    {
      id: "payment_type.type.en",
      label: "Payment Method",
      align: "left",
      order: false,
      fallback: "N/A",
    },

    {
      id: "payment_transaction_id",
      label: "Payment Transaction ID",
      align: "left",
      order: false,
      fallback: "N/A",
    },
    {
      id: "driver_rating",
      label: "Rating",
      align: "left",
      rate: true,
      order: false,
      fallback: "N/A",
    },
  ],
  hide: {
    create: true,
  },
  permission: "list",
  actions: ["show"],
};
const show = {
  fields: [
    {
      type: "text",
      name: "user.fullName",
      label: "User",
    },
    {
      type: "text",
      name: "user.fullMobile",
      label: "Mobile",
    },
    {
      type: "mixed",
      name: "driver.first_name",
      label: "Driver",
      elements: [
        {
          type: "text",
          name: "en",
          label: "English",
        },
        {
          type: "text",
          name: "ar",
          label: "Arabic",
        },
      ],
    },
    {
      type: "mixed",
      name: "class.title",
      label: "Class",
      elements: [
        {
          type: "text",
          name: "en",
          label: "English",
        },
        {
          type: "text",
          name: "ar",
          label: "Arabic",
        },
      ],
    },
    {
      type: "mixed",
      name: "package.title",
      label: "Package",
      elements: [
        {
          type: "text",
          name: "en",
          label: "English",
        },
        {
          type: "text",
          name: "ar",
          label: "Arabic",
        },
      ],
    },
    {
      type: "text",
      name: "pickupTime",
      label: "Pickup time",
    },
    {
      type: "map",
      name: "pickup",
      label: "Pickup",
      long: {
        name: "lng",
        label: "Longitude",
      },
      apiKey: "AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4",
    },
    {
      type: "map",
      name: "destination",
      label: "Destination",
      long: {
        name: "lng",
        label: "Longitude",
      },
      apiKey: "AIzaSyAHR3gtXsLJgtNsLXRWoliFlL_DJeElSv4",
    },
    {
      type: "mixed",
      name: "guest",
      label: "Guest info",
      elements: [
        {
          type: "text",
          name: "luggage_number",
          label: "Luggage",
          nullable: true,
        },
        {
          type: "text",
          name: "fullname",
          label: "Full Name",
          nullable: true,
        },
        {
          type: "text",
          name: "mobile_number",
          label: "Mobile number",
          nullable: true,
        },
        {
          type: "text",
          name: "flight_number",
          label: "Flight number",
          nullable: true,
        },
        {
          type: "text",
          name: "signage_name",
          label: "Signage Name",
          nullable: true,
        },
      ],
    },
    {
      type: "text",
      name: "gate.airport.title.en",
      label: "Airport",
    },
    {
      type: "text",
      name: "gate.title.en",
      label: "Gate",
    },
    {
      type: "text",
      name: "status",
      label: "Status",
    },
    {
      type: "text",
      name: "cancel_reason",
      label: "Cancel Reason",
    },
    {
      type: "text",
      name: "user_feedback",
      label: "User feedback",
    },
  ],
  permission: "show",
};

const filter = {
  fields: [
    {
      type: "select",
      async: true,
      name: "user_id",
      route: "users-mobile",
      label: "Customer Name",
    },

    {
      type: "select",
      async: true,
      name: "driver_id",
      route: "drivers-list",
      label: "Driver",
    },
    {
      type: "select",
      name: "class_id",
      label: "Car Class",
      async: true,
      route: "classes-list",
    },
    {
      type: "text",
      name: "total_distance",
      label: "Total Distance",
      async: true,
    },
    {
      type: "text",
      name: "$price.base_fare$",
      label: "Base fare",
      async: true,
    },

    {
      type: "select",
      async: true,
      name: "package_id",
      route: "packages",
      label: "Packages",
    },
    {
      type: "select",
      async: true,
      name: "payment_id",
      route: "payment_methods",
      label: "Payment Methods",
    },
    {
      type: "date",
      name: "date.from",
      label: "Date From",
    },
    {
      type: "date",
      name: "date.to",
      label: "Date To",
    },
  ],
};
const csv = {
  headers: list.header.map((item) => ({
    key: item.id,
    label: item.label,
  })),
};

const search = {
  fields: [
    {
      type: "text",
      name: "$user.first_name$",
    },
    {
      type: "text",
      name: "$user.last_name$",
    },
    {
      type: "text",
      name: "status",
    },
  ],
};

const data = { list, show, filter, csv, search };

export default data;
