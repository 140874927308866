const list = {
    header: [
      {
        id: 'user_name',
        label: 'User name',
        align: 'center',
        fallback: 'N/A'
        
    },
        {
            id: 'customer_name',
            label: 'Customer Name',
            align: 'center',
            fallback: 'N/A'
            
        },
        {
            id: 'card',
            label: 'Card',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'pickup_location.location_name',
            label: 'Pickup location',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'dropoff_location.location_name',
            label: 'Drop off location',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'startedat',
            label: 'Start Date',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'endtime',
            label: 'End Date',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'driver_name',
            label: 'Driver name',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'class',
            label: 'Car class',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'total_distance',
            label: 'Total Distance (KM)',
            align: 'center',
            fallback: 'N/A'
        },
        {
          id: 'base_fare',
          label: 'Base Fare (QAR)',
          align: 'center',
          fallback: 'N/A'
      },
        {
            id: 'total_time',
            label: 'Total Time (MIN)',
            align: 'center',
            fallback: 'N/A'
        },
        {
            id: 'trip_total',
            label: 'Trip Total (QAR)',
            align: 'center',
            fallback: 'N/A'
        },
        
        {
            id: 'request_type.en',
            label: 'Request Type',
            align: 'center',
            fallback: 'N/A'
        },
    ],
    permission: 'list',
    hide: {
        create: true
    },
    actions: ['show'],
};
const search = {
  fields: [
    {
      type: "text",
      name: "$appointment_request.user.first_name$",
    },
    {
      type: "text",
      name: "$appointment_request.user.last_name$",
    },
 
  ],
};

const filter = {
    fields: [
      {
        type: "select",
        async: true,
        name: "$appointment_request.user.id$",
        route: "users-mobile",
        label: "Customer Name",
      },
      {
        type: "text",
        name: "card",
        label: "Card",
      },
     
    
      {
        type: "select",
        async: true,
        name: "$appointment_request.driver.id$",
        route: "drivers-list",
        label: "Driver",
      },
      {
        type: "select",
        name: "$appointment_request.class.id$",
        label: "Car Class",
        async: true,
        route: "classes-list",
      },
      {
        type: "text",
        name: "$appointment_request.total_distance$",
        label: "Total Distance",
      },
      {
        type: "text",
        name: "$appointment_request.price.base_fare$",
        label: "Base Fare",
      },
        {
          type: "text",
          name: "$appointment_request.total$",
          label: "Trip Total",
        },
    
  
      {
        type: "select",
        async: true,
        name: "$appointment_request.package.id$",
        route: "packages",
        label: "Request Type",
      },
  
      {
        type: "date",
        name: "date.from",
        label: "Date From",
      },
      {
        type: "date",
        name: "date.to",
        label: "Date To",
      },
      {
        type: "select",
        name: "status",
        label: "Status",
        values: [
          { value: "done", label: "Done" },
          { value: "waiting", label: "Waiting" },
          { value: "accepted", label: "Accepted" },
          { value: "canceled", label: "Canceled" },
          { value: "in progress", label: "In Progress" },
          { value: "payment-pending", label: "Payment Pending" },
        ],
      },
      {
        type: "select",
        async: true,
        name: "payment_id",
        route: "payment_methods",
        label: "Payment Methods",
      },
    ],
  };

const csv = {
    headers: list.header.map(item => ({
      key: item.id,
      label: item.label,
    }))
  };
const data = { list, search, csv ,filter};

export default data;