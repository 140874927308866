const list = {
    header: [
        {
            id: "user.fullName",
            label: "Customer Name",
            align: "left",
            fallback: 'N/A'
        },
        {
            id: "total",
            label: "Amount",
            align: "left",
        },
        {
            id: "pickup.location_name",
            label: "Pickup Location",
            align: "left",
        },
        {
            id: "destination.location_name",
            label: "Drop off Location",
            align: "left",
        },
        {
            id: "started_at",
            label: "Start Date",
            align: "left",
        },
        {
            id: "end_time",
            label: "End Date",
            align: "left",
        },
        {
            id: "driver.driverName",
            label: "Driver Name",
            align: "left",
        },
        {
            id: "plate_number",
            label: "Plate Number",
            align: "left",
            fallback: 'N/A',
        },
        {
            id: "class.title.en",
            label: "Car Type",
            align: "left",
        },
        {
            id: "total_distance",
            label: "Total Distance",
            align: "left",
        },
        {
            id: "price.base_fare",
            label: "Base Fare (QAR)",
            align: "left",
        },
        {
            id: "appointment.total_time",
            label: "Total Time",
            align: "left",
        },
        {
            id: "appointment.total",
            label: "Trip Total (QAR)",
            align: "left",
        },
        {
            id: "appointment.refunded_amount",
            label: "Refunded Amount (QAR)",
            align: "left",
        },
    ],
    permissions: "list",
    actions: ["show"],
};

const search = {
    fields: [
        {
            type: "text",
            name: "$user.first_name$",
        },
        {
            type: "text",
            name: "$user.last_name$",
        },
    ],
};

const filter = {
    fields: [
        {
            type: "select",
            async: true,
            name: "$user.id$",
            route: "users-mobile",
            label: "Customer Name",
        },
        {
            type: "text",
            name: "total",
            label: "Amount",
        },
        {
            type: "select",
            async: true,
            name: "$driver.id$",
            route: "drivers-list",
            label: "Driver",
        },
        {
            type: "select",
            name: "$class.id$",
            label: "Car Class",
            async: true,
            route: "classes-list",
        },
        {
            type: "text",
            name: "$appointment.total_distance$",
            label: "Total Distance",
        },
        {
            type: "text",
            name: "$price.base_fare$",
            label: "Base Fare",
        },
        {
            type: "text",
            name: "$total$",
            label: "Trip Total",
        },
        {
            type: "select",
            async: true,
            name: "$package.id$",
            route: "packages",
            label: "Request Type",
        },
        {
            type: "date",
            name: "date.from",
            label: "Date From",
        },
        {
            type: "date",
            name: "date.to",
            label: "Date To",
        },
        {
            type: "select",
            name: "status",
            label: "Status",
            values: [
                { value: "done", label: "Done" },
                { value: "waiting", label: "Waiting" },
                { value: "accepted", label: "Accepted" },
                { value: "canceled", label: "Canceled" },
                { value: "in progress", label: "In Progress" },
                { value: "payment-pending", label: "Payment Pending" },
            ],
        }
    ],
};

const csv = {
    headers: list.header.map(item => ({
        key: item.id,
        label: item.label,
    }))
};


const data = { list, search, csv, filter };

export default data;
